/* Container for the checkout form */
.checkout-container {
    max-width: 700px; 
    margin: 0 auto;
    padding: 1.5rem; /* Reduced padding */
    background-color: #17384e; /* Softer background color */
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1); /* Softer shadow */
    border-radius: 8px; /* Less rounded corners */
    font-family: 'Arial', sans-serif;
   margin-top: 30px;
}

/* Title Styling */
.checkout-title {
    text-align: center;
    font-size: 1.8rem; /* Slightly smaller font size */
    color: #444; /* Softer color */
    margin-bottom: 1.5rem; /* Reduced bottom margin */
}

/* Box that wraps product details */
.checkout-box {
    background-color: white;
    padding: 15px; /* Reduced padding */
    border-radius: 8px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08); /* Lighter shadow */
    margin-bottom: 1.5rem;
}

/* Product summary styling */
.product-summary h3 {
    font-size: 1.4rem;
    margin-bottom: 0.5rem;
    color: #333;
}

.product-summary p {
    font-size: 1rem;
    margin-bottom: 1.5rem; /* Reduced spacing */
}

.price {
    font-weight: bold;
    color: #d9534f; /* Red color for price */
}

/* Checkout Form */
.checkout-form {
    display: flex;
    flex-direction: column;
    gap: 1.2rem; /* Reduced gap between elements */
}

/* Form group styling */
.form-group {
    display: flex;
    flex-direction: column;
}

label {
    font-size: 0.95rem; /* Slightly smaller label font */
    font-weight: 600;
    margin-bottom: 0.4rem;
    color: #555;
}

input, textarea {
    padding: 8px; /* Reduced padding */
    border: 1px solid #ddd; /* Softer border color */
    border-radius: 5px;
    font-size: 0.95rem; /* Reduced font size */
    width: 100%;
    box-sizing: border-box;
    transition: border 0.3s ease;
}

input:focus, textarea:focus {
    border-color: #007bff;
    outline: none;
}

textarea {
    resize: vertical;
    height: 100px; /* Reduced textarea height */
}
.add-order-button{
    background: #5E5DF0;
    border-radius: 999px;
    box-shadow: #5E5DF0 0 10px 20px -10px;
    box-sizing: border-box;
    color: #FFFFFF;
    cursor: pointer;
    font-family: Inter,Helvetica,"Apple Color Emoji","Segoe UI Emoji",NotoColorEmoji,"Noto Color Emoji","Segoe UI Symbol","Android Emoji",EmojiSymbols,-apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans",sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    opacity: 1;
    outline: 0 solid transparent;
    padding: 8px 18px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: fit-content;
    word-break: break-word;
    border: 0;
}

/* Pay button styling */
.pay-now-button {
      display: flex;
      align-items: center;
      font-family: inherit;
      font-weight: 500;
      font-size: 16px;
      padding: 0.7em 1.4em 0.7em 1.1em;
      color: white;
      background: #ad5389;
      background: linear-gradient(0deg, rgba(20,167,62,1) 0%, rgba(102,247,113,1) 100%);
      border: none;
      box-shadow: 0 0.7em 1.5em -0.5em #14a73e98;
      letter-spacing: 0.05em;
      border-radius: 20em;
      margin-top: 2vw;
      cursor: pointer;
      user-select: none;
      -webkit-user-select: none;
      touch-action: manipulation;
    }
    
    .button-88:hover {
      box-shadow: 0 0.5em 1.5em -0.5em #14a73e98;
    }
    
    .button-88:active {
      box-shadow: 0 0.3em 1em -0.5em #14a73e98;
    }


.pay-button:hover {
    background-color: #0056b3;
}

/* Responsive styling */
@media (max-width: 768px) {
    .checkout-container {
        padding: 1rem;
    }

    .checkout-title {
        font-size: 1.6rem; /* Reduced font size for smaller screens */
    }

    .pay-button {
        font-size: 1rem; /* Reduced button font size for mobile */
    }
}
