.as_service_box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 400px; /* Adjust the height as per your design */
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
}

.as_service_box:hover {
    transform: scale(1.05);
}

.as_icon img {
    width: 50px; /* Ensure the icons are also consistent in size */
    height: auto;
    margin-bottom: 5px;
}

.as_subheading {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 10px;
}

.as_service_box p {
    flex-grow: 1;
    margin-bottom: 20px;
}

.as_link {
    color: #ff7010;
    font-weight: bold;
    text-decoration: none;
}

.as_link:hover {
    text-decoration: underline;
}
@media only screen and (max-width: 768px) {
 .as_icon{

    margin-left: 15vh !important;
 }



}