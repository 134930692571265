.wp-float-btn {
    position: fixed;
    width: 40px;
    height: 40px;
    bottom: 30px;
    right: 25px;
    background-color: #25d366;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    z-index: 1000;
    padding: 10px;
    animation: heartbeat 3s infinite;
}

@keyframes heartbeat {
    0% {
        transform: scale(1);
    }

    14% {
        transform: scale(1.3);
    }

    28% {
        transform: scale(1);
    }

    42% {
        transform: scale(1.3);
    }

    70% {
        transform: scale(1);
    }
}