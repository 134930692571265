.shop-details-container {
    display: flex;
    justify-content: center;
    padding: 20px;
    background-color: #f9f9f9;
}

.product-card {
    display: flex;
    max-width: 1200px;
    width: 100%;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    flex-direction: row;
}

.product-image-section {
    flex: 1;
    padding: 20px;
    text-align: center;
}

.product-image {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 10px;
}

.image-carousel {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.thumbnail-image {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
}

.product-info-section {
    flex: 1;
    padding: 60px;
    margin-top: 5vw;
}

.product-title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 10px;
}

.product-reviews {
    margin-bottom: 10px;
    color: #f5a623;
}

.review-count {
    font-size: 14px;
    margin-left: 10px;
    color: #555;
}

.product-price {
    font-size: 20px;
    margin: 10px 0;
    color: #333;
}

.original-price {
    text-decoration: line-through;
    color: #999;
    margin-right: 10px;
}

.product-description {
    font-size: 1.2rem;
    color: #666;
    margin-bottom: 20px;
}

.pack-options {
    margin-bottom: 20px;
}

.pack-buttons {
    display: flex;
    gap: 10px;
}

.pack-btn {
    padding: 10px 15px;
    background: #eee;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
}

.product-buttons .btn {
    padding: 10px 20px;
    font-size: 16px;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .product-card {
        flex-direction: column;
    }

    .product-info-section {
        padding: 20px;
        margin-top: 20px;
    }

    .product-title {
        font-size: 1.5rem;
    }

    .product-price {
        font-size: 18px;
    }

    .product-description {
        font-size: 1rem;
    }

    .pack-btn {
        padding: 8px 12px;
        font-size: 14px;
    }

    .product-buttons .btn {
        padding: 8px 16px;
        font-size: 14px;
    }

    .thumbnail-image {
        width: 40px;
        height: 40px;
    }
}

@media (max-width: 480px) {
    .product-info-section {
        padding: 15px;
        margin-top: 15px;
    }

    .product-title {
        font-size: 1.2rem;
    }

    .product-price {
        font-size: 16px;
    }

    .product-description {
        font-size: 0.9rem;
    }

    .pack-btn {
        padding: 6px 10px;
        font-size: 12px;
    }

    .product-buttons .btn {
        padding: 6px 12px;
        font-size: 12px;
    }

    .thumbnail-image {
        width: 30px;
        height: 30px;
    }
}
