.form_modal.popup {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 90%;
    max-width: 400px;
    padding: 20px;
    background: rgba(111, 52, 1, 0.95);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    z-index: 10;
    animation: fadeIn 0.3s ease-in-out;
}

.form_container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.form_container input {
    width: 100%;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.form_container button {
    margin-top: 10px;
}

@keyframes fadeIn {
    from { opacity: 0; transform: scale(0.95); }
    to { opacity: 1; transform: scale(1); }
}
.button {
    background-color: #007BFF; /* Blue background */
    border: none; /* Remove border */
    color: white; /* White text */
    padding: 10px 20px; /* Top/bottom and left/right padding */
    text-align: center; /* Center the text */
    text-decoration: none; /* Remove underline */
    display: inline-block; /* Display inline */
    font-size: 16px; /* Increase font size */
    margin: 4px 2px; /* Margin */
    cursor: pointer; /* Pointer cursor on hover */
    border-radius: 5px; /* Rounded corners */
    transition: background-color 0.3s ease, transform 0.2s; /* Smooth transitions */
}

/* Hover effects */
.button:hover {
    background-color: #0056b3; /* Darker blue on hover */
    transform: translateY(-2px); /* Lift effect */
}

/* Active effect */
.button:active {
    background-color: #004494; /* Even darker blue when clicked */
    transform: translateY(1px); /* Slightly move down */
}

/* Specific styles for close button */
.close-button {
    background-color: #dc3545; /* Red background */
}

/* Close button hover effect */
.close-button:hover {
    background-color: #c82333; /* Darker red on hover */
}
